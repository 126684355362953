<div
    class="overlay-menu"
    [class.left]="settings?.position === 'left'"
    [class.middle]="settings?.position === 'middle'"
    [class.show]="show"
    (click)="delayedClose()"
>
    <div class="menu" (click)="cancelClose()">
        <div class="menu__header">
            <!-- <button mat-icon-button (click)="close()">
                <app-icon [icon]="{ class: 'material-icons', content: 'close' }"></app-icon>
            </button> -->

            <div class="profile" *ngIf="current">
                <div class="avatar">
                    <img [src]="current.photo ? current.photo : 'assets/img/avatar.svg'" alt="avatar" />
                </div>
                <div>
                    <div class="name" [matTooltip]="user_groups">{{ current.name }}</div>
                    <div class="location" *ngIf="current.location?.name">{{ current.location }}</div>
                </div>
            </div>
        </div>

        <div class="menu__body">
            <ul>
                <ng-container *ngIf="!!menu_items">
                    <ng-container *ngFor="let item of menu_items">
                        <ng-container *ngIf="item.route; else external_link">
                            <li>
                                <a
                                    [routerLink]="[item.route]"
                                    routerLinkActive="active"
                                    [queryParams]="item.query_params"
                                >
                                    <div class="text">{{ item.name }}</div>
                                    <div class="menu-icon">
                                        <img [src]="item.icon.src" />
                                    </div>
                                </a>
                            </li>

                            <ng-container *ngIf="item.children && item.children.length">
                                <li>
                                    <a
                                    class="sub-link"
                                        *ngFor="let sub_item of item.children"
                                        [routerLink]="[sub_item.route]"
                                        routerLinkActive="active"
                                        [queryParams]="sub_item.query_params"
                                    >
                                        <div class="text">{{ sub_item.name }}</div>
                                        <app-icon
                                            [icon]="{
                                                type: 'icon',
                                                class: 'material-icons',
                                                content: 'keyboard_arrow_right'
                                            }"
                                        ></app-icon>
                                    </a>
                                </li>
                            </ng-container>
                        </ng-container>

                        <ng-template #external_link>
                            <li>
                                <a [href]="item.link | safe: 'url'">
                                    <div class="text">{{ item.name }}</div>
                                    <app-icon
                                        [icon]="{
                                            type: 'icon',
                                            class: 'material-icons',
                                            content: 'exit_to_app'
                                        }"
                                    ></app-icon>
                                </a>
                            </li>
                        </ng-template>
                    </ng-container>
                    <ng-container>
                        <li>
                            <a (click)="logout()">
                                <div class="text">Logout</div>
                            </a>
                        </li>
                    </ng-container>
                </ng-container>
            </ul>
        </div>

        <div class="footer">
            <div class="text-sm text-opacity-60 text-white" *ngIf="version">Version: {{ version }}</div>
        </div>
    </div>
</div>

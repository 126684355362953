import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DeskInductionErrorData {
    buildingName: string;
    bookingDate: number;
}

@Component({
    selector: 'desk-flow-induction-error',
    template: `
        <main class="p-4 relative">
            <h1 class="text-xl font-medium" mat-dialog-title>
                NO INDUCTION DONE
            </h1>
            <div mat-dialog-content>
                <p class="pb-4">
                    Thank you for your request to book a desk at
                    {{ buildingName }} on {{ bookingDate }}. Before we are able
                    to accept your request, you are required to have completed
                    the {{ buildingName }} virtual induction which a pre
                    requisite of returning to our corporate workplaces.
                </p>
                <p class="pb-4">
                    These inductions have been created to ensure the health and
                    wellbeing of our people following an extended period away
                    from the office. They only need to be done once, and should
                    you plan on working across multiple workplaces, each has
                    their own unique version.
                </p>
                <p class="pb-4">
                    Can you please ensure you have completed your online digital
                    induction, and also consider completing your fire warden
                    training which will help us during this phase to keep safe,
                    well, and remain compliant to building code.
                </p>
                <p class="pb-4">
                    Please visit our return to the workplace checklist to access
                    the outstanding pre-requisites
                    <a
                        class="link"
                        href="https://lendlease.sharepoint.com/sites/AUST_CoronaVirus/SitePages/Return-to-the-Workplace---Checklist.aspx"
                        target="_blank"
                        >https://lendlease.sharepoint.com/sites/AUST_CoronaVirus/SitePages/Return-to-the-Workplace---Checklist.aspx</a
                    >
                </p>
                <p class="pb-4">
                    Once you have completed your pre requisites, please wait for
                    five minutes for the backend system to update and resubmit
                    the access request form,
                </p>
                <p class="pb-4">
                    We look forward to welcoming you to our workplace soon.
                </p>
                <p>Kind Regards</p>
                <p><i>The Corporate Real Estate team</i></p>
            </div>
            <div mat-dialog-actions>
                <a button mat-button mat-dialog-close class="error">Dismiss</a>
                <a
                    href="https://lendlease.sharepoint.com/sites/AUST_CoronaVirus/SitePages/Return-to-the-Workplace---Checklist.aspx"
                    target="_blank"
                    button
                    mat-button
                    cdkFocusInitial
                >
                    Complete Induction
                </a>
            </div>
        </main>
    `,
    styles: [
        `
            main {
                width: 40rem;
                max-width: calc(100vw - 4.5rem);
            }
            .link {
                color: var(--primary);
                text-decoration: underline;
            }
        `,
    ],
})
export class DeskInductionErrorModalComponent {
    public readonly buildingName = this._data.buildingName;
    public readonly bookingDate = this._data.bookingDate;

    constructor(
        @Inject(MAT_DIALOG_DATA) private _data: DeskInductionErrorData
    ) {}
}

import { Component, EventEmitter, Output } from '@angular/core';
import { DialogEvent } from '@user-interfaces/common';
import { BuildingLevel, Desk } from '@user-interfaces/organisation';

export interface DeskFlowConfirmModalData {
    desk: Desk;
    date: number;
    reason: string;
    level: BuildingLevel;
}

@Component({
    selector: 'building-closed-modal-component',
    template: `
        <header>
            <h2>Workplace Unavailable</h2>
        </header>
        <ng-container>
            <main class="p-4">
                <p>
                    Please note at this time this workplace is closed and unavailable for desk bookings. Please visit the workplace page on the COVID microsite for more information
                </p>
                <br/>
                <p>
                    Thank you and we hope to reopen bookings at this workplace soon.
                </p>
            </main>
            <footer class="flex items-center justify-center p-2">
                <button mat-button (click)="confirm()">OK</button>
            </footer>
        </ng-container>
    `,
    styles: [
        `
            main {
                width: 24rem;
                max-width: calc(100vw - 4.5rem);
            }
        `,
    ],
})
export class BuildingClosedModalComponent {
    @Output() public event = new EventEmitter<DialogEvent>();

    public confirm() {
        this.event.emit({ reason: 'done' });
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle } from '@angular/platform-browser';

@Pipe({
    name: 'join'
})
export class JoinPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    /**
     * Sanitizes the string allowing it to be injected into a template
     * @param value String to sanitize
     * @param type Type of value to sanitise. `resource`, `url`, `script`, `style` or `html`
     */
    public transform(
        value: string[],
        join: string = ', '
    ): string {
        return value.join(join);
    }
}

<header>
    <app-icon
        [icon]="{ type: 'icon', class: 'material-icons', content: 'people_outline' }"
    ></app-icon>
    <h1>Attendees</h1>
</header>
<mat-dialog-content>
    <div class="body">
        <div *ngFor="let item of attendees">
            <app-icon
                [icon]="{ type: 'icon', class: 'material-icons', content: 'perm_identity' }"
            ></app-icon>
            <label>{{ item.name }}</label>
            <a href="mailto:{{ item.email }}">{{ item.email }}</a>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <footer>
        <button mat-button class="inverse" mat-dialog-close>Close</button>
    </footer>
</mat-dialog-actions>

<header>
    <div class="heading" mat-dialog-title>
        Attendees Availability
    </div>
    <button mat-icon-button mat-dialog-close>
        <app-icon [icon]="{ type: 'icon', class: 'material-icons', content: 'close' }"></app-icon>
    </button>
</header>
<mat-dialog-content>
    <main>
        <div class="date">
            <a-date-field
                name="date"
                [ngModel]="date"
                (ngModelChange)="changeDate($event)"
            ></a-date-field>
        </div>
        <ng-container *ngIf="!loading; else loader">
            <div class="mobile-only">
                <vertical-timeline
                    [(date)]="date"
                    [(duration)]="duration"
                    [(groups)]="groups"
                ></vertical-timeline>
            </div>
            <div class="not-mobile">
                <event-timeline
                    [(date)]="date"
                    [(duration)]="duration"
                    [(groups)]="groups"
                ></event-timeline>
            </div>
        </ng-container>
    </main>
</mat-dialog-content>
<mat-dialog-actions>
    <footer>
        <button mat-button mat-dialog-close class="inverse">
            Cancel
        </button>
        <button mat-flat-button [disabled]="is_past" color="primary" (click)="save()">
            Save
        </button>
    </footer>
</mat-dialog-actions>
<ng-template #loader>
    <div class="spinner">
        <mat-spinner diameter="32"></mat-spinner>
    </div>
</ng-template>

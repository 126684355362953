import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { getModule, queryUsers, showUser } from '@placeos/ts-client';
import {
    DialogEvent,
    flatten,
    notifyError,
    notifyInfo,
    notifySuccess,
    randomInt,
    SettingsService,
} from '@user-interfaces/common';
import { Desk, OrganisationService } from '@user-interfaces/organisation';
import { StaffService, StaffUser, User } from '@user-interfaces/users';
import {
    addMinutes,
    endOfDay,
    format,
    getUnixTime,
    isSameDay,
    startOfDay,
} from 'date-fns';
import { catchError, first, map } from 'rxjs/operators';
import { Booking } from './booking.class';
import { BookingsService } from './bookings.service';

import { DeskConfirmModalComponent } from './desk-confirm-modal.component';
import { DeskInductionErrorModalComponent } from './desk-induction-error-modal.component';
import { DeskQuestionsModalComponent } from './desk-questions-modal.component';
import { getRecurrenceDates, RecurrencePeriod } from './helpers';

@Injectable({
    providedIn: 'root',
})
export class DesksService {
    public can_set_date: boolean = true;
    public error_on_host: boolean = true;

    constructor(
        private _org: OrganisationService,
        private _dialog: MatDialog,
        private _users: StaffService,
        private _bookings: BookingsService,
        private _settings: SettingsService
    ) {}

    private async hasCompletedTraining(
        email: string,
        system_id: string,
        zone_id: string
    ) {
        const mod = getModule(system_id, 'Training');
        if (mod) {
            const result = await mod.execute('induction_complete?', [
                email,
                zone_id,
            ]);
            return result;
        }
        return false;
    }

    public async checkTraining(
        date: number | Date,
        host: User,
        attendees: User[] = [],
        desks: Desk[] = []
    ): Promise<User[]> {
        const building = this._org.find(
            this._org.levelWithID([desks[0]?.zone.id])?.parent_id
        );
        if (
            !(await this.hasCompletedTraining(
                host.raw_email || host.email,
                this._org.organisation.bindings.training,
                this._org.levelWithID([desks[0]?.zone.id])?.parent_id
            ))
        ) {
            this._dialog.open(DeskInductionErrorModalComponent, {
                data: {
                    buildingName: building.display_name || building.name,
                    bookingDate: format(date, 'dd/MM/yyyy'),
                },
            });
            return [];
        }
        const has_completed = await Promise.all(
            attendees.map((user) =>
                this.hasCompletedTraining(
                    user.raw_email || user.email,
                    this._org.organisation.bindings.training,
                    this._org.levelWithID([desks[0].zone.id])?.parent_id
                )
            )
        );
        const failed = has_completed
            .map((_, idx) => (!_ ? attendees[idx].name : false))
            .filter((_) => !!_);
        if (failed.length) {
            notifyError(
                `${failed.join(
                    ', '
                )} have not completed induction for the selected building.`
            );
        }
        return has_completed
            .map((_, idx) => (_ ? attendees[idx] : false))
            .filter((_) => !!_) as User[];
    }

    public async bookDesk({
        desks,
        host,
        reason,
        attendees,
        date,
        recurrence,
        group,
    }: {
        desks: Desk[];
        host?: StaffUser;
        attendees?: User[];
        reason?: string;
        date?: Date;
        group?: boolean;
        recurrence?: { period: RecurrencePeriod; end: number };
    }) {
        console.log('Attendees:', attendees);
        if (this.error_on_host && !host) {
            return notifyError('You need to select a host to book a desk.');
        }
        host =
            !host || host.email === this._users.current?.email
                ? this._users.current
                : ((await queryUsers({ q: host.email })
                      .pipe(
                          map((_) => _.data[0]),
                          catchError((_) => null)
                      )
                      .toPromise()) as any);
        if (!host) {
            return notifyError(
                'Unable to complete this booking, it does not appear the user has previously logged into Workplace.'
            );
        }
        console.log('Host:', host);
        reason = reason || '';
        const level = this._org.levelWithID(
            desks[0].zone instanceof Array ? desks[0].zone : [desks[0].zone?.id]
        );
        let ref: MatDialogRef<any> = this._dialog.open(
            DeskQuestionsModalComponent
        );
        let success = await Promise.race([
            ref.componentInstance.event
                .pipe(first((_: DialogEvent) => _.reason === 'done'))
                .toPromise(),
            ref
                .afterClosed()
                .pipe(map((_) => null))
                .toPromise(),
        ]);
        if (!success) return;
        ref.close();
        ref = this._dialog.open(DeskConfirmModalComponent, {
            data: {
                desks,
                date: date ? new Date(date) : new Date(),
                reason,
                level,
                can_set_date: this.can_set_date,
                change_host: !!this._settings.get('app.desks.can_change_host'),
                user: host,
                allow_group: !!group,
                attendees,
            },
        });
        success = await Promise.race([
            ref.componentInstance.event
                .pipe(first((_: DialogEvent) => _.reason === 'done'))
                .toPromise(),
            ref
                .afterClosed()
                .pipe(map((_) => null))
                .toPromise(),
        ]);
        if (!success) return;
        host = ref.componentInstance.user || host;
        attendees = ref.componentInstance.attendees || attendees;
        desks = ref.componentInstance.desk_list || desks;
        date = ref.componentInstance.date;
        reason = ref.componentInstance.reason;
        const completed = await this.checkTraining(
            date,
            host,
            attendees?.length ? attendees : [host],
            desks
        );
        if (!completed?.length) {
            notifyError(
                `${
                    attendees.length
                        ? 'No attendees have'
                        : host.name + ' has not'
                } completed induction for this building`
            );
            return this._dialog.closeAll();
        }
        ref.componentInstance.loading =
            'Checking for existing desk bookings...';
        const user_list = attendees?.length
            ? await Promise.all(
                  completed.map((_) =>
                      queryUsers({ q: _.email })
                          .pipe(map((_) => _.data[0]))
                          .toPromise()
                          .catch((e) => _)
                  )
              )
            : [host];
        console.log('User List:', user_list, host, attendees);
        const bookings = flatten(
            await Promise.all(
                user_list
                    .filter((_) => _?.email)
                    .map((_) =>
                        this._bookings.query({
                            type: 'desk',
                            period_start: getUnixTime(
                                startOfDay(date || new Date())
                            ),
                            period_end: getUnixTime(
                                endOfDay(date || new Date())
                            ),
                            email: _.email,
                            zones:
                                desks[0].zone?.parent_id || desks[0].zone?.id,
                        })
                    )
            )
        );
        const desk_list = bookings.filter(
            (d) =>
                (attendees?.length
                    ? completed.find(
                          (_) =>
                              d.user_email.toLowerCase() ===
                              _.email.toLowerCase()
                      )
                    : d.user_email.toLowerCase() ===
                      host.email.toLowerCase()) && !d.rejected
        );
        if (desk_list?.length) {
            ref.close();
            const users = desk_list.map((_) => _.user_name || _.user_email);
            return notifyError(
                `${
                    completed.length ? users.join(', ') : 'You'
                } currently already have a desk booked for the selected date.`
            );
        }
        ref.componentInstance.loading = 'Booking desk...';
        console.log('Completed:', completed, desk_list);
        const group_id = `dg-${randomInt(9999_9999, 1000_0000)}`;
        const list = await Promise.all([
            completed.map((user, idx) =>
                this.makeDeskBooking(
                    desks[idx],
                    host,
                    date.valueOf() || new Date().valueOf(),
                    reason,
                    user,
                    recurrence || {
                        period: ref.componentInstance.recurr_period,
                        end: ref.componentInstance.recurr_end,
                    },
                    attendees?.length ? group_id : undefined
                )
            ),
        ]);
        if (list.find((_) => _ instanceof Booking)) {
            notifySuccess('Successfully booked desk');
        }
        ref.close();
        return true;
    }

    private async makeDeskBooking(
        desk: Desk,
        host: StaffUser,
        date: number,
        reason: string,
        for_user: User = null,
        recurrence: { period: RecurrencePeriod; end: number } = null,
        group?: string
    ) {
        const location = `${desk.zone?.name}-${desk.id}`;
        const level = this._org.levelWithID(
            desk.zone instanceof Array ? desk.zone : [desk.zone?.id]
        );
        const zones = desk.zone?.id
            ? [desk.zone?.id, level?.parent_id]
            : [level?.parent_id];
        const dates = getRecurrenceDates(
            date,
            recurrence?.period || 'none',
            recurrence?.end
        );
        const bookings = await this._bookings.query({
            period_start: getUnixTime(startOfDay(dates[0])),
            period_end: getUnixTime(endOfDay(dates[dates.length - 1])),
            type: 'desk',
        });
        const bookable_dates =
            dates.length > 1
                ? dates.filter(
                      (_) =>
                          !bookings.find(
                              (bkn) => isSameDay(bkn.date, _) && !bkn.rejected
                          )
                  )
                : dates;
        const non_bookable_dates =
            dates.length > 1
                ? dates.filter(
                      (_) => !bookable_dates.find((date) => isSameDay(date, _))
                  )
                : [];
        const series_id =
            dates.length > 1
                ? `${randomInt(9999)}_${new Date().valueOf()}`
                : '';
        if (!bookable_dates.length) {
            notifyError('No available booking slots for the selected date(s).');
            throw new Error(
                'No available booking slots for the selected date(s).'
            );
        }
        if (non_bookable_dates.length) {
            notifyInfo(
                `The selected desk(s) are unavailable for the date(s) ${non_bookable_dates
                    .map((_) => format(_, 'dd MMM'))
                    .join(',')}`
            );
        }
        return Promise.all(
            bookable_dates.map((day) => {
                const date = new Date(`${format(day, 'yyyy-MM-dd')}T12:00:00Z`);
                const booking_data = {
                    booking_start: getUnixTime(date),
                    user_id: (for_user || host).id,
                    user_name: (for_user || host).name,
                    user_email: (for_user || host).email,
                    booker_id: this._users.current.id,
                    booker_name: this._users.current.name,
                    booker_email: this._users.current.email,
                    booking_end: getUnixTime(addMinutes(date, 30)),
                    asset_id: desk.id,
                    title: reason,
                    description: location,
                    zones,
                    booking_type: 'desk',
                    extension_data: {
                        groups: desk.groups,
                        group_id: group,
                        for_user: for_user?.email,
                        series_id,
                        series_start: dates[0],
                        series_end: dates[dates.length - 1],
                    },
                };
                return this._bookings.save(booking_data as any);
            })
        );
    }
}

<div class="item" *ngIf="space">
    <div class="details">
        <div class="name">{{ space.name }}</div>
        <div class="info">{{ space.level?.name }}</div>
    </div>
    <div class="capacity" *ngIf="space.capacity !== -1">
        <app-icon [icon]="{ class: 'material-icons', content: 'account_circle' }"></app-icon>
        <div class="text">{{ space.capacity }}</div>
    </div>
    <div class="actions">
        <button mat-icon-button *ngIf="multiple; else single_button" (click)="action.emit()">
            <app-icon
                [icon]="{
                    class: 'material-icons',
                    content: type === 'available' ? 'add' : 'close'
                }"
            ></app-icon>
        </button>
    </div>
</div>
<ng-template #single_button>
    <button mat-button color="primary" [class.request]="space.by_request">
        {{ space.by_request ? 'Request' : 'Book' }}
    </button>
</ng-template>

import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogEvent, notifyError } from '@user-interfaces/common';
import {
    BuildingLevel,
    Desk,
    OrganisationService,
} from '@user-interfaces/organisation';
import { User } from '@user-interfaces/users';
import {
    addDays,
    addMonths,
    getUnixTime,
    endOfDay,
    startOfDay,
} from 'date-fns';
import { queryBookings } from './bookings.fn';

export interface DeskFlowConfirmModalData {
    desks: Desk[];
    date: number;
    reason: string;
    level: BuildingLevel;
    user?: User;
    attendees?: User[];
    change_host?: boolean;
    allow_group?: boolean;
}

@Component({
    selector: 'desk-confirm-modal-component',
    template: `
        <header>
            <h2>Confirm Booking</h2>
            <div class="flex-1"></div>
            <button mat-icon-button mat-dialog-close *ngIf="!loading">
                <app-icon className="material-icons">close</app-icon>
            </button>
        </header>
        <ng-container *ngIf="!loading; else load_state">
            <main class="p-4">
                <div class="mb-4">
                    <label>Date</label>
                    <div>{{ date | date: 'mediumDate' }}</div>
                </div>
                <div class="mb-4" *ngIf="can_change_host && !allow_group">
                    <label>Host</label>
                    <a-user-search-field
                        [(ngModel)]="user"
                    ></a-user-search-field>
                </div>
                <div class="mb-4 flex flex-col">
                    <label>Reason</label>
                    <mat-form-field appearance="outline">
                        <input
                            matInput
                            name="title"
                            placeholder="No Reason"
                            [(ngModel)]="reason"
                        />
                    </mat-form-field>
                </div>
                <!-- <div class="mb-4 flex flex-col">
                    <label>Recurrence</label>
                    <mat-form-field appearance="outline">
                        <mat-select [(ngModel)]="recurr_period">
                            <mat-option value="none">None</mat-option>
                            <mat-option value="weekdays">Daily</mat-option>
                            <mat-option value="weekly">Weekly</mat-option>
                            <mat-option value="monthly">Monthly</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="mb-4 flex flex-col">
                    <label>Recurrence End</label>
                    <a-date-field
                        [(ngModel)]="recurr_end"
                        [disabled]="!recurr_period || recurr_period === 'none'"
                        [from]="date"
                        [to]="recurr_limit"
                    ></a-date-field>
                </div> -->
                <div class="flex flex-col" *ngIf="allow_group">
                    <label>Group Members</label>
                    <a-user-list-field
                        [filter]="filter_fn"
                        [hideActions]="true"
                        [(ngModel)]="attendees"
                        (ngModelChange)="setAttendees($event)"
                    ></a-user-list-field>
                </div>
                <p>
                    Your desk will be {{ desks }} on
                    {{ level?.display_name || level?.name }}
                </p>
            </main>
            <footer class="flex items-center justify-center p-2">
                <button
                    mat-button
                    [disabled]="allow_group && !attendees.length"
                    (click)="confirm()"
                >
                    Confirm
                </button>
            </footer>
        </ng-container>
        <ng-template #load_state>
            <main class="flex flex-col p-12 items-center justify-center">
                <mat-spinner [diameter]="48" class="mb-4"></mat-spinner>
                <p>{{ loading }}</p>
            </main>
        </ng-template>
    `,
    styles: [
        `
            main {
                width: 24rem;
                max-width: calc(100vw - 4.5rem);
            }
        `,
    ],
})
export class DeskConfirmModalComponent {
    @Output() public event = new EventEmitter<DialogEvent>();
    /** Selected date for desk booking */
    public date = this._data.date;
    /** Selected recurrence period for booking */
    public recurr_period = 'none';
    /** User's reason for making desk booking */
    public reason = this._data.reason;
    /** User to assign the desk booking to */
    public user = this._data.user;
    /** List of selected desks */
    public desk_list: Desk[] = this._data.desks || [];
    /** Whether user is allowed to change the host */
    public can_change_host = this._data.change_host;
    /** List of users in the groups */
    public attendees =
        (this._data.attendees?.length ? this._data.attendees : null) ||
        this.can_change_host
            ? []
            : [this._data.user];
    /** End date for the selected recurrence period */
    public recurr_end = addDays(this.date, 1);
    /** Whether booking for groups is allowed */
    public allow_group = this._data.allow_group ?? false;
    /** Whether booking request is being processed */
    public loading: string;
    /** Level of the selected desk */
    public readonly level = this._data.level;
    /** Last date the recurrence can occur on */
    public readonly recurr_limit = addMonths(this._data.date, 6);

    public readonly desk = this._data.desks[0];

    public get desks() {
        return this.desk_list.map((_) => _.name).join(', ');
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) private _data: DeskFlowConfirmModalData,
        private _org: OrganisationService
    ) {
        if (this._data.attendees?.length) {
            this.setAttendees(this._data.attendees);
        }
    }

    public async setAttendees(list: User[]) {
        const [desks, bookings] = await Promise.all([
            this._org.loadZoneDesks(this.level.id),
            queryBookings({
                period_start: getUnixTime(startOfDay(this.date)),
                period_end: getUnixTime(endOfDay(this.date)),
                type: 'desk',
                zones: this.level.id,
            }).toPromise(),
        ]);
        const available = desks.filter(
            (d) => d.bookable && !bookings.find((_) => _.asset_id === d.id)
        );
        if (available.length < list.length) {
            notifyError(
                'No enough desks available for group on the selected level'
            );
        } else {
            let index = Math.max(
                0,
                available.findIndex((_) => _.id === this.desk?.id)
            );
            if (index + list.length > available.length)
                index = available.length - list.length;
            this.desk_list = available.slice(index, index + list.length);
            console.log(
                'Desks:',
                index,
                list.length,
                this.desk_list,
                available
            );
        }
    }

    /** Perform booking for desk */
    public confirm() {
        this.loading = 'Requesting desk booking...';
        this.event.emit({ reason: 'done', metadata: { reason: this.reason } });
    }
}

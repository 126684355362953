import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'split',
})
export class SplitPipe implements PipeTransform {
    public transform(
        value: string,
        split: string = '_',
        join: string = ' '
    ): string {
        return value.split(split).join(join);
    }
}

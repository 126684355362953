import {
    addBusinessDays,
    addDays,
    addMonths,
    addWeeks,
    addYears,
    startOfDay,
} from 'date-fns';

export type RecurrencePeriod =
    | 'none'
    | 'daily'
    | 'weekdays'
    | 'weekly'
    | 'bi-weekly'
    | 'fortnightly'
    | 'monthly'
    | 'yearly';

export function getRecurrenceDates(
    start: number,
    period: RecurrencePeriod,
    end: number = start
): number[] {
    const dates = [];
    let add_fn = addDays;
    switch (period) {
        case 'none':
            return [start];
        case 'weekdays':
            add_fn = addBusinessDays;
            break;
        case 'weekly':
            add_fn = addWeeks;
            break;
        case 'bi-weekly':
        case 'fortnightly':
            add_fn = (d, _) => addWeeks(d, 2);
            break;
        case 'monthly':
            add_fn = addMonths;
            break;
        case 'yearly':
            add_fn = addYears;
            break;
    }
    let date = startOfDay(start).valueOf();
    end = startOfDay(end).valueOf();
    while (date <= end) {
        dates.push(date);
        date = add_fn(date, 1).valueOf();
    }
    return dates;
}

<div class="topbar-header px-12 py-4" [class.reverse]="reverse" [class.no-bg]="noBackgroundColor">
    <div class="topbar-header__logo">
        <a [routerLink]="['/home']" *ngIf="logo">
            <div class="logo">
                <i *ngIf="logo.type === 'icon'" [class]="logo.class">{{ logo.content }}</i>
                <img *ngIf="logo.type === 'img'" class="h-10" [src]="logo.src | safe: 'resource'" />
            </div>
        </a>
    </div>
    
    <!-- <div class="topbar-header__search">
        <div
            class="search-wrapper"
            matAutocompleteOrigin
            #origin="matAutocompleteOrigin"
        >
            <app-icon
                class="search-icon"
                [icon]="{ type: 'img', src: 'assets/img/icon-search.svg' }"
            ></app-icon>
            <input
                [(ngModel)]="search_str"
                (ngModelChange)="updateSearch($event)"
                [matAutocomplete]="auto"
                [matAutocompleteConnectedTo]="origin"
                class="search"
                placeholder="Search for people or spaces"
            >
        </div>

        <mat-autocomplete #auto="matAutocomplete">
            <mat-option
                class="p-0"
                *ngFor="let option of search_results | async"
                [value]="option.name"
            >
                <a
                    button
                    mat-button
                    class="clear w-full text-left h-full rounded-none text-normal text-base"
                    [routerLink]="['/explore']"
                    [queryParams]="option.map_id ? { space: option.id } : { user: option.email }"
                >
                    <div class="flex items-center">
                        <div class="text-xs w-12 text-primary">{{
                            option.map_id ? 'Space' : 'Staff'
                        }}</div>
                        {{ option.name }}
                    </div>
                </a>
            </mat-option>
        </mat-autocomplete>
    </div> -->

    <div class="topbar-header__menu">
        <button mat-icon-button (click)="showMenu()">
            <img [src]="'assets/img/avatar.svg'" alt="avatar" />
        </button>
    </div>
</div>

import { BuildingLevel } from './level.class';
export class Desk {
    /** ID of the desk also map_id */
    public readonly id: string;
    /** Name of the desk */
    public readonly name: string;
    /** Whether desk is available / bookable */
    public readonly bookable: boolean;
    /** Zone/Level of the desk */
    public readonly zone: BuildingLevel;
    /** Group/Department allocated to the desk */
    public readonly groups: string[];
    /** List of features associated with the desk */
    public readonly features: readonly string[];

    constructor(data) {
        this.id = data.id || '';
        this.name = data.name || '';
        this.bookable = !!(data.bookable && data.bookable !== 'FALSE');
        this.zone = data.zone || data.parent_id;
        this.groups = (data.groups instanceof Array ? data.groups : []).map(_ => _.toLowerCase());
        const feature_list = data.features instanceof Array ? data.features : [];
        if (data.sit_to_stand) {
            feature_list.push('sit_to_stand');
        }
        if (data.dual_monitors) {
            feature_list.push('dual_monitors');
        }
        this.features = feature_list;
    }

    public format() {
        return { ...this };
    }
}
